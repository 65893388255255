header {
  overflow: hidden;
  padding-top: 4rem;
  height: max-content;
}

.header__container {
  text-align: center;
  height: 100%;
  position: relative;
}

/* ============== CTA ============== */
.cta {
  margin-top: 2.5rem;
  display: flex;
  gap: 1.2rem;
  justify-content: center;
}

/* ============== SOCIALS ============== */
.header__socials {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.8rem;
  position: absolute;
  left: 0;
  bottom: 9rem;
}

.header__socials::after {
  content: "";
  width: 1px;
  height: 2rem;
  background: var(--color-primary);
}

/* ============== ME ============== */
.me {
  background: linear-gradient(var(--color-primary), transparent);
  width: 22rem;
  height: 30rem;
  position: relative;
  left: calc(50% - 11rem);
  margin-top: 4rem;
  border-radius: 12rem 12rem 0 0;
  overflow: hidden;
  padding: 3rem 1.5rem 0 1.5rem;
}

/* ============== SCROLL DOWN ============== */
.scroll__down {
  position: absolute;
  right: -2.3rem;
  bottom: 15rem;
  transform: rotate(90deg);
  font-weight: 300;
  font-size: 0.9rem;
}

/* ================= MEDIA QUERIES (TABLET 768px) ================= */
@media screen and (max-width: 768px) {
  header {
    padding-top: 8rem;
  }

  .header__socials,
  .scroll__down {
    display: none;
  }

  .me {
    width: 20rem;
    height: 26rem;
    left: calc(50% - 10rem);
  }
}

/* ================= MEDIA QUERIES (MOBILE 425px) ================= */

@media screen and (max-width: 425px) {
  header {
    padding-top: 7rem;
  }

  .cta {
    gap: 0.8rem;
  }

  .me {
    width: 16rem;
    height: 20rem;
    left: calc(50% - 8rem);
    margin-top: 3rem;
  }
}
