.contact__container {
  width: 50%;
  display: grid;
  grid-template-columns: 43% 50%;
  gap: 7%;
}

.contact__options {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}

.contact__option {
  background: var(--color-bg-variant);
  padding: 1.2rem;
  border-radius: 1.2rem;
  text-align: center;
  border: 1px solid transparent;
  transition: var(--transition);
}

.contact__option:hover {
  background: transparent;
  border-color: var(--color-primary-variant);
}

.contact__option-icon {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.contact__option a {
  margin-top: 0.7rem;
  display: inline-block;
  font-size: 0.8rem;
}

/* =============== FORM =============== */
form {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

input,
textarea {
  width: 100%;
  padding: 1rem;
  border-radius: 0.5rem;
  background-color: transparent;
  border: 2px solid var(--color-primary-variant);
  resize: none;
  color: var(--color-white);
}

.btn.btn-primary.submit-btn {
  padding: 0.48rem 0.42rem;
  font-size: 1rem;
}

/* ================= MEDIA QUERIES (LAPTOP 1024px) ================= */

@media screen and (max-width: 1024px) {
  .contact__container {
    grid-template-columns: 1fr;
    gap: 2rem;
    width: var(--container-width-xl);
  }

  .contact__options {
    flex-direction: unset;
    justify-content: center;
    gap: 2rem;
  }

  .contact__option:nth-of-type(1),
  .contact__option:nth-of-type(3) {
    flex-basis: 27%;
  }

  .contact__option:nth-of-type(2) {
    transform: scale(1.1);
  }

  form {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
  }

  textarea {
    grid-row: 2;
    grid-column: 1/3;
  }

  button {
    grid-row: 3;
  }
}

/* ================= MEDIA QUERIES (TABLET 768px) ================= */

@media screen and (max-width: 768px) {
  .contact__container {
    width: var(--container-width-md);
  }

  .contact__options {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 1rem;
  }
  .contact__option:nth-of-type(1) {
    grid-column: 1/4;
    grid-row: 2;
  }
  .contact__option:nth-of-type(2) {
    transform: unset;
    grid-column: 2/6;
    grid-row: 1;
  }
  .contact__option:nth-of-type(3) {
    grid-column: 4/7;
    grid-row: 2;
  }

  .contact__option {
    padding: 0.5rem;
  }

  form {
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  textarea {
    grid-row: unset;
    grid-column: unset;
  }

  button {
    grid-row: unset;
    margin: auto;
  }
}

/* ================= MEDIA QUERIES (MOBILE 425px) ================= */

@media screen and (max-width: 425px) {
  .contact__container {
    width: var(--container-width-sm);
  }

  h5 {
    font-size: 0.7rem;
  }

  input,
  textarea {
    padding: 0.5rem;
  }

  .btn.btn-primary.submit-btn {
    padding: 0.48rem 0.42rem;
    font-size: 0.9rem;
  }
}
