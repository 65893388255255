.services__container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
}

.service {
  background: var(--color-bg-variant);
  border-radius: 0 0 2rem 2rem;
  border: 1px solid var(--color-primary);
  height: fit-content;
  transition: var(--transition);
}

.service:hover {
  background: transparent;
  border-color: var(--color-primay-variant);
  cursor: default;
}

.service__head {
  background: var(--color-primary);
  padding: 1.5rem;
  border-radius: 0 0 2rem 2rem;
  box-shadow: 0 2rem 1rem rgba(0, 0, 0, 0.1);
}

.service__head h3 {
  color: var(--color-bg);
  font-size: 1rem;
  text-align: center;
}

.service__list {
  padding: 2rem;
}

.service__list li {
  display: flex;
  gap: 1rem;
  margin-bottom: 0.8rem;
}

.service__list-icon {
  color: var(--color-primary);
  margin-top: 2px;
}

.service__list p {
  font-size: 0.9rem;
}
/* ================= MEDIA QUERIES (LAPTOP 1024px) ================= */

@media screen and (max-width: 1200px) {
  .services__container {
    width: 50%;
    grid-template-columns: 1fr;
  }

  .service__head {
    padding: 0.5rem;
    border-radius: 0 0 3rem 3rem;
  }

  .service {
    height: auto;
    width: 100%;
  }

  .service__list li {
    justify-content: center;
  }
}

/* ================= MEDIA QUERIES (LAPTOP 1024px) ================= */

@media screen and (max-width: 1024px) {
  .services__container {
    width: 60%;
  }
}

/* ================= MEDIA QUERIES (TABLET 768px) ================= */

@media screen and (max-width: 768px) {
  .services__container {
    width: var(--container-width-md);
  }

  .service__list {
    padding: 1rem;
  }
}

/* ================= MEDIA QUERIES (MOBILE 425px) ================= */

@media screen and (max-width: 425px) {
  .services__container {
    width: var(--container-width-sm);
  }

  .service__head {
    padding: 0.2rem;
  }

  .service__list li {
    margin-bottom: 0.5rem;
    line-height: 1.3rem;
  }
}
