.about__container {
  display: grid;
  grid-template-columns: 30% 60%;
  gap: 10%;
}

.about__me {
  width: 100%;
  aspect-ratio: 1/1;
  border-radius: 2rem;
  background: linear-gradient(
    45deg,
    transparent,
    var(--color-primary),
    transparent
  );
  display: grid;
  place-items: center;
}
.about__me-image {
  border-radius: 2rem;
  overflow: hidden;
  transform: rotate(10deg);
  transition: var(--transition);
}

.about__me-image:hover {
  transform: rotate(0deg);
}

.about__cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
}

.about__card {
  background: var(--color-bg-variant);
  border: 1px solid transparent;
  border-radius: 1rem;
  padding: 1rem;
  text-align: center;
  transition: var(--transition);
}

.about__card:hover {
  background: transparent;
  border-color: var(--color-primary-variant);
  cursor: default;
}

.about__icon {
  color: var(--color-primary);
  font-size: 1.4rem;
  margin-bottom: 1rem;
}

.about__card h5 {
  font-size: 0.96rem;
}

.about__card small {
  font-size: 0.7rem;
  color: var(--color-light);
}

.about__content p {
  margin: 2rem 0 2.6rem;
  color: var(--color-light);
}

/* ================= MEDIA QUERIES (LAPTOP 1024px) ================= */
@media screen and (max-width: 1024px) {
  .about__container {
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  .about__me {
    width: 30%;
    margin: 2rem auto 4rem;
  }

  .about__content p {
    margin: 3rem 0 1.5rem;
  }

  .about__cards {
    gap: 3rem;
  }

  .about__card {
    padding: 2rem;
  }

  .about__card:nth-of-type(2) {
    transform: scale(1.2);
  }
}

/* ================= MEDIA QUERIES (TABLET 768px) ================= */
@media screen and (max-width: 768px) {
  .about__container {
    gap: 0rem;
  }

  .about__me {
    width: 45%;
    margin: 0 auto 3rem;
  }

  .about__cards {
    grid-template-columns: repeat(4, 1fr);
    grid-row: 2;
    gap: 1rem;
  }
  .about__card:nth-of-type(1) {
    grid-column: 2/4;
    grid-row: 2;
  }
  .about__card:nth-of-type(2) {
    transform: unset;
    grid-column: 1/3;
    grid-row: 1;
  }
  .about__card:nth-of-type(3) {
    grid-column: 3/5;
    grid-row: 1;
  }

  .about__card {
    padding: 0.5rem;
  }

  .about__content {
    text-align: center;
  }

  .about__content p {
    margin: 1rem 0 1.5rem;
  }
}

/* ================= MEDIA QUERIES (MOBILE 425px) ================= */

@media screen and (max-width: 425px) {
  .about__cards {
    gap: 0.5rem;
  }

  .about__content p {
    font-size: 0.9rem;
  }
}
