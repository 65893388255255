footer {
  background: var(--color-primary);
  padding: 1rem 0 3rem;
  text-align: center;
  font-size: 0.9rem;
  margin-top: 7rem;
}

footer a {
  color: var(--color-bg);
}

.footer__logo {
  font-size: 2rem;
  font-weight: 500;
  margin-bottom: 1rem;
  display: inline-block;
}

.permalinks {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
  margin: 0 auto 2rem;
}

.footer__socials {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 2rem;
}

.footer__socials a {
  background: var(--color-bg);
  color: var(--color-white);
  padding: 0.8rem;
  border-radius: 0.7rem;
  display: flex;
  border: 1px solid transparent;
}

.footer__socials a:hover {
  background: transparent;
  color: var(--color-bg);
  border-color: var(--color-bg);
}

.footer__copyright {
  margin-bottom: 4rem;
  color: var(--color-bg);
}

/* ================= MEDIA QUERIES (TABLET) ================= */

@media screen and (max-width: 768px) {
  footer {
    padding: 1.5rem 0;
  }

  .permalinks {
    gap: 0.5rem 1rem;
    margin: 0 auto 2rem;
  }

  .footer__socials {
    margin-bottom: 1.5rem;
  }

  .footer__copyright {
    margin-bottom: 0rem;
  }
}

/* ================= MEDIA QUERIES (MOBILE 425px) ================= */

@media screen and (max-width: 425px) {
  footer {
    padding: 1rem 0;
  }

  .permalinks {
    gap: 0.2rem 1.2rem;
    margin: 0 auto 1rem;
    font-size: 0.8rem;
  }

  .footer__socials {
    margin-bottom: 2rem;
    gap: 2rem;
  }

  .footer__socials a {
    padding: 0.5rem;
  }
}
