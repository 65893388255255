.container.testimonials__container {
  width: 50%;
  padding-bottom: 3rem;
}

.client__avatar {
  width: 4rem;
  aspect-ratio: 1/1;
  overflow: hidden;
  border-radius: 50%;
  margin: 0 auto 1rem;
  border: 0.4rem solid var(--color-primary-variant);
}

.testimonial {
  background: var(--color-bg-variant);
  text-align: center;
  padding: 2rem;
  border-radius: 2rem;
  user-select: none;
}

.client__review {
  color: var(--color-light);
  font-weight: 300;
  display: block;
  width: 80%;
  margin: 0.8rem auto 0;
}

.swiper-pagination-clickable .swiper-pagination-bullet {
  background: var(--color-primary);
  width: 10px;
  height: 10px;
}

/* ================= MEDIA QUERIES (LAPTOP 1024px) ================= */

@media screen and (max-width: 1024px) {
  .container.testimonials__container {
    width: 60%;
  }
}

/* ================= MEDIA QUERIES (TABLET 768px) ================= */

@media screen and (max-width: 768px) {
  .container.testimonials__container {
    width: var(--container-width-md);
  }
  .client__review {
    width: unset;
  }
}

/* ================= MEDIA QUERIES (MOBILE 425px) ================= */

@media screen and (max-width: 425px) {
  .container.testimonials__container {
    width: var(--container-width-sm);
  }

  .testimonial {
    padding: 1rem;
  }
}
