.experience__container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.5rem;
}

.experience__container > div {
  background: var(--color-bg-variant);
  padding: 2.4rem 3.5rem;
  border-radius: 2rem;
  border: 1px solid transparent;
  transition: var(--transition);
}

.experience__container > div:hover {
  background: transparent;
  border-color: var(--color-primary-variant);
  cursor: default;
}

.experience__container > div h3 {
  text-align: center;
  margin-bottom: 2rem;
  color: var(--color-primary);
}

.experience__content {
  display: grid;
  row-gap: 2rem;
  column-gap: 4rem;
  grid-template-columns: 1fr 1fr;
}

.experience__details {
  display: flex;
  gap: 1rem;
}

.experience__details-icon {
  margin-top: 6px;
  color: var(--color-primary);
}

/* ================= MEDIA QUERIES (LAPTOP LARGE 1200px) ================= */
@media screen and (max-width: 1200px) {
  .experience__content {
    column-gap: 0.5rem;
  }
}

/* ================= MEDIA QUERIES (LAPTOP 1024px) ================= */

@media screen and (max-width: 1024px) {
  .experience__container {
    grid-template-columns: 1fr;
    width: 60%;
    /* gap: 2rem; */
  }

  .experience__container > div {
    width: 100%;
    margin: 0 auto;
  }

  .experience__content {
    column-gap: 2rem;
  }
}

/* ================= MEDIA QUERIES (TABLET 768px) ================= */

@media screen and (max-width: 768px) {
  .experience__container {
    gap: 2rem;
    width: var(--container-width-md);
  }

  .experience__container > div {
    width: 100%;
    padding: 3rem 3rem;
  }

  /* .experience__content {
    column-gap: 7rem;
    width: 80%;
    margin: auto;
  } */
}

/* ================= MEDIA QUERIES (MOBILE 425px) ================= */

@media screen and (max-width: 425px) {
  .experience__container {
    width: var(--container-width-sm);
  }

  .experience__container > div {
    padding: 1.5rem;
  }

  .experience__content {
    column-gap: 1rem;
    row-gap: 1rem;
  }

  h4 {
    font-size: 0.8rem;
  }

  .text-light {
    font-size: 0.7rem;
  }
}
